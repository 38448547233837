$lightGreen: #b9e7e7;


@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

#root{

  overflow-x: hidden;
  
  }
.app {
  display: flex;
  justify-content: center;
  .container-fluid {
    // width: 1150px;

    .link {
      text-decoration: none;
      color: #1B2951;
    }
    //LOGIN & REGISTER

    .auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      background-color: white;

      h1 {
        font-size: 20px;
        color: black;
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        width: 350px;
        gap: 20px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
        }

        button {
          padding: 10px;
          border: none;
          background-color: #89C000;
          cursor: pointer;
          color: white;
          border-radius: 25px;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    //NAVBAR

    // .navbar {
    //   .container {
    //     padding: 10px 0px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     .logo {
    //       img {
    //         width: 120px;
    //       }
    //     }
    //     .links {
    //       display: flex;
    //       align-items: center;
    //       gap: 10px;

    //       h6 {
    //         font-size: 16px;
    //         font-weight: 300;
    //       }

    //       span {
    //         cursor: pointer;
    //       }

    //       .write {
    //         background-color: $lightGreen;
    //         width: 50px;
    //         height: 50px;
    //         border-radius: 50%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         font-weight: 300;
    //         border: 1px solid white;

    //         &:hover {
    //           color: teal;
    //           background-color: white;
    //           border: 1px solid teal;
    //         }
    //       }
    //     }
    //   }
    // }

    //FOOTER

    // footer {
    //   margin-top: 100px;
    //   padding: 20px;
    //   background-color: $lightGreen;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   font-size: 12px;
    //   img {
    //     height: 50px;
    //   }
    // }
    

    //HOME

    .home {
      .posts {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 75px;
        // width: 1422px;
        align-items: center;
        justify-content: center;

        .post {
          display: flex;
          gap: 100px;
          width: 1150px;
          align-items: center;
          justify-content: center;

          &:nth-child(2n + 1) {
            flex-direction: row-reverse;
          }

          .img {
            flex: 2;
            position: relative;

            &::after {
              content: "";
              width: 100%;
              height: 100%;
              // background-color: $lightGreen;
              position: absolute;
              top: 20px;
              left: -20px;
              z-index: -1;
            }

            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
            }
          }

          .content {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
              font-size: 48px;
            }

            p {
              font-size: 18px;
            }

            button {
              width: max-content;
              padding: 10px 20px;
              border: none;
              cursor: pointer;
              background-color: #89C000;
              // border: 1px solid teal;
              border : 1px solid #89C000;
              color: white;

              &:hover {
                border: 1px solid white;
                background-color: #1B2951;
                color: white;
              }
            }
          }
        }
      }
    }

    //SINGLE

    .single {
      display: flex;
      gap: 50px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 30px;

        #imgHead {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 13px;
          font-size: 14px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-weight: bold;
          }
        }

        .edit {
          display: flex;
          gap: 5px;
          margin-bottom: 7px;
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }

        h1 {
          font-size: 42px;
          color: #333;
        }

        p {
          // text-align: justify;
          line-height: 27px;
          font-size: 1.30rem;
        }
      }
      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 25px;

        h1 {
          font-size: 20px;
          color: #555;
        }

        .post {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            width: 80%;
            height: 200px;
            object-fit: cover;
          }

          h2 {
            color: #555;
          }

          button {
            width: max-content;
            padding: 7.5px 15px;
            border: none;
            cursor: pointer;
            color: white;
            background-color: #89C000;
            border: 1px solid #89c000;

            &:hover {
              border: 1px solid white;
              background-color: #1B2951;
              color: white;
            }
          }
        }
      }
    }

    //WRITE

    .add {
      margin-top: 20px;
      display: flex;
      gap: 20px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editorContainer {
          height: 300px;
          overflow: scroll;
          border: 1px solid lightgray;

          .editor {
            height: 100%;
            border: none;
          }
        }
      }

      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          border: 1px solid lightgray;
          padding: 10px;
          flex:1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 12px;
          color: #555;

          h1{
            font-size: 20px;
          }

          .file{
            text-decoration: underline;
            cursor: pointer;
          }

          .buttons{
            display: flex;
            justify-content: space-between;

            :first-child{
              cursor: pointer;
              color: teal;
              background-color: white;
              border: 1px solid teal;
              padding: 3px 5px;
            }
            :last-child{
              cursor: pointer;
              color: white;
              background-color: #89c000;
              border: 1px solid #89c000;
              padding: 3px 5px;
            }
          }

          .cat{
            display: flex;
            align-items: center;
            gap: 2px;
            color: #1B2951;
            font-size: 14px;
          }
        }
      }
    }
  }
}

input[type=file]::file-selector-button {
  // margin-right: 20px;
  border: none;
  background: #1B2951;
  padding: 10px 20px;
  border-radius: 1px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #89c000;
}


.item-pagination-prevnext {
  display: flex;
  // justify-content: space-between;
  background-color: grey;
  padding: 10vw 0;
  .item-pagination-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      cursor: pointer;
    }
    .item-pagination-icon {
      position: relative;
      padding: 0 25px;
    }
    .item-pagination-link-next {
      display: flex;
      justify-content: flex-end;
      color: red;
      align-items: center;
    }
  }}


    .add1 {
      // margin-top: 20px;
      // display: flex;
      gap: 20px;

      .content1 {
        // flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editorContainer1 {
          height: 250px;
          overflow: scroll;
          border: 1px solid lightgray;

          .editor1 {
            height: 100%;
            border: none;
          }
        }
      }

      .menu1 {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item1 {
          border: 1px solid lightgray;
          padding: 10px;
          flex:1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 12px;
          color: #555;

       

          .file{
            text-decoration: underline;
            cursor: pointer;
          }

   

          .cat1{
            display: flex;
            align-items: center;
            gap: 2px;
            color: #1B2951;
            font-size: 14px;
          }
        }
      }
      .buttons1{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        

        :first-child{
          cursor: pointer;
          color: teal;
          background-color: white;
          border: 1px solid teal;
          padding: 3px 5px;
        }
        :last-child{
          cursor: pointer;
          color: white;
          background-color: #89c000;
          border: 1px solid #89c000;
          padding: 3px 5px;
        }
      }
    }

input[type=file]::file-selector-button {
  // margin-right: 20px;
  border: none;
  background: #1B2951;
  padding: 10px 20px;
  border-radius: 1px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #89c000;
}


.item-pagination-prevnext {
  display: flex;
  // justify-content: space-between;
  background-color: grey;
  padding: 10vw 0;
  .item-pagination-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      cursor: pointer;
    }
    .item-pagination-icon {
      position: relative;
      padding: 0 25px;
    }
    .item-pagination-link-next {
      display: flex;
      justify-content: flex-end;
      color: red;
      align-items: center;
    }
  }}


  .button_mobile {
    width: -webkit-fill-available;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #89C000;
    // border: 1px solid teal;
    border : 1px solid #89C000;
    color: white;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 1px solid white;
      background-color: #1B2951;
      color: white;
    }
  }


  .ck-editor__editable {
    min-height: 250px;
}


// .card {
//   height: 300px; /* Adjust the value as per your requirement */
// }

// Tab potrait mode
@media (min-width: 500px){
  .card-img-top {
  // width: 100%;
  // height: 40vh;
  padding: 14px;
  border-radius: 8px;
  width: 19em;
  height: 16em;
  // object-fit: cover;
  }
}

// Tab landscape mode
@media (min-width: 1000px) and (max-width : 1100px){
  .card-img-top {
  // width: 100%;
  // height: 40vh;
  padding: 14px;
  border-radius: 8px;
  width: 27em;
  height: 15em;
  // object-fit: cover;
  }
}


.card-img-topMobile {
  // width: 100%;
  // height: 40vh;
  // padding: 14px;
  // border-radius: 8px;
  // width: 24rem;
  height: 18rem;
  object-fit: cover;
  object-position: top right;
  width: -webkit-fill-available;
  }


.card-img-topUltrawide {
  // width: 100%;
  // height: 40vh;
  padding: 14px;
  border-radius: 8px;
  // width: 20em;
  height: 9rem;
  object-fit: cover;
  object-position:top right;
  }


.card-img-topDefined {
  padding: 14px;
  border-radius: 8px;
  width: 15em;
  height: 15em;
  
  }

// .card-body{
//   // width: 100%;
//   min-height: 25%;
//   // object-fit: cover;

//   display: flex;
//   flex-direction: column;
//   align-content: flex-start;
//   justify-content: space-between;
//   }

 

.break-after-30ch {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  max-width: 27ch;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
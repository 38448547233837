
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}



#root{

  overflow-x: hidden;
  
  }
/* body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  } */
  
  a {
    color: #89c000;
    text-decoration: none;
  }
  
  a:hover {
    color: #000000;
    text-decoration: none;
  }
  
  /* h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  } */
  
  /* h5{
    color: #ffffff;
  }
   */
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #89c000;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #89c000;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: rgb(255, 255, 255);
  }
  
  #header.header-scrolled {
    background: rgb(255, 255, 255);
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    /* align-items: center; */
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 7px 15px;
    margin-left: 5px;
    font-size: 14px;
    color: rgb(28,41,81);
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  /* .navbar a:hover, */
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #FFFFFF;
    background: #89c000;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 5px;
    top: calc(100% + 30px);
    margin: 5px 0 0 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 15px;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    /* padding: 10px 20px; */
    padding: 5px 8px;
    color: #ffffff;
    margin: 0 5px;
    font-size: 14px;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  /* .mobile-nav-toggle {
    color: #89C000;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  } */
  
  /* @media (min-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
   */
  /* .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(204, 204, 204, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    margin: 5px;
    font-size: 15px;
    color: #2a2c39;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #fff;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  } */
  .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
}

  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    overflow: hidden;
    position: relative;
    /* background: linear-gradient(0deg, rgb(28,41,81) 0%, rgb(28,41,81) 100%); */
    padding: 0;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    text-align: left;
    position: relative;
    height: 24vh;
    padding-top: 65px;
  }
  
  /* #hero h2 { */
    /* color: #fff; */
    /* margin-bottom: 30px; */
    /* font-size: 48px; */
    /* font-weight: 700; */
    /* font-size:'3.3rem', */
    /* line-height:'0.9em', */
  /* } */
  
  /* #hero p {
    width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
    font-size:'24px';
    font-weight:'300';
    line-height:'0.9em';
  } */
  
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon,
  #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .btn-get-started {
    /* font-family: "Roboto", sans-serif; */
    /* font-weight: 500; */
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid #89c000;
  }
  
  #hero .btn-get-started:hover {
    background: #89c000;
    color: #fff;
    text-decoration: none;
  }
  
  @media (min-width: 1024px) {
    #hero p {
      width: 60%;
    }
  
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 768px) {
    #hero .carousel-container {
      height: 40vh;
    }
  
    #hero h2 {
      font-size: 28px;
    }
  }
  
  .hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
  }
  
  .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
  }
  
  .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
  }
  
  .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
  }
  
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
  
    100% {
      transform: translate(-90px, 0%);
    }
  }
  
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }
  
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-title {
    padding-bottom: 28px;
    padding-left: 72px;
  }
  .section-titleUltrawide {
    padding-bottom: 40px;
    padding-left: 20px;
  }

  .section-titleMobile {
    padding-bottom: 40px;
    /* padding-left: 72px; */
  }
  
  .section-title h2 {
    font-size: 30px;
    font-weight: 700;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #89C000;
    /* font-family: "Poppins", sans-serif; */
  }
  
  .section-titleMobile h2 {
    font-size: 30px;
    font-weight: 700;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #89C000;
    /* font-family: "Poppins", sans-serif; */
  }

  .section-titleUltrawide h2 {
    font-size: 30px;
    font-weight: 700;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #89C000;
    /* font-family: "Poppins", sans-serif; */
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #89C000;
    margin: 4px 10px;
  }

  .section-titleMobile h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #89C000;
    margin: 4px 10px;
  }
  .section-titleUltrawide h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #89C000;
    margin: 4px 10px;
  }
  
  .section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    /* font-family: "Poppins", sans-serif; */
    color: #1C2951;
  }
  .section-titleMobile p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    /* font-family: "Poppins", sans-serif; */
    color: #1C2951;
  }
  
  .section-titleUltrawide p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    /* font-family: "Poppins", sans-serif; */
    color: #1C2951;
  }
  
  /*--------------------------------------------------------------
  # Latest
  --------------------------------------------------------------*/
  .Latest {
    padding-top: 40px;
  }
  
  .Latest .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .Latest .content ul {
    list-style: none;
    padding: 0;
  }
  
  .Latest .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .Latest .content ul li+li {
    margin-top: 10px;
  }
  
  .Latest .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #89c000;
    line-height: 1;
  }
  
  .Latest .content p:last-child {
    margin-bottom: 0;
  }
  
  .Latest .content .btn-learn-more {
    /* font-family: "Raleway", sans-serif; */
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #89c000;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #89c000;
  }
  
  .Latest .content .btn-learn-more:hover {
    background: #89c000;
    color: #fff;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Blogs
  --------------------------------------------------------------*/
  .Blogs .nav-tabs {
    border: 0;
  }
  
  .Blogs .nav-link {
    border: 1px solid #d4d6df;
    padding: 15px;
    transition: 0.3s;
    color: #2a2c39;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Blogs .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .Blogs .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .Blogs .nav-link:hover {
    color: #89c000;
  }
  
  .Blogs .nav-link.active {
    background: #89c000;
    color: #fff;
    border-color: #89c000;
  }
  
  @media (max-width: 768px) {
    .Blogs .nav-link i {
      padding: 0;
      line-height: 1;
      font-size: 36px;
    }
  }
  
  @media (max-width: 575px) {
    .Blogs .nav-link {
      padding: 15px;
    }
    
    .Blogs .nav-link i {
      font-size: 24px;
    }
  }
  
  .Blogs .tab-content {
    margin-top: 30px;
  }
  
  .Blogs .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .Blogs .tab-pane ul {
    list-style: none;
    padding: 0;
  }
  
  .Blogs .tab-pane ul li {
    padding-bottom: 10px;
  }
  
  .Blogs .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #89c000;
  }
  
  .Blogs .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: #2a2c39;
    padding: 80px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    /* font-family: "Raleway", sans-serif; */
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: #89c000;
  }
  
  .cta .cta-btn:hover {
    background: #fff;
    color: #89c000;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #89c000;
    float: left;
    width: 44px;
    height: 44px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2a2c39;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555974;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #89c000;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #89c000;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #89c000;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #89c000;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #89c000;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #89c000;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    margin-top: 70px;
    background: #f7f8f9;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs ol {
      margin-top: 10px;
    }
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #404356;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  
  /* latest blog card */
  
  /* component */
  
  .banner {
    display: block;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(230, 13%, 9%, 0.05),
      0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    border-radius: 0.375em;
    transition: 0.3s;
    width: 50%;
    margin-inline: auto;
    height: 300px !important;
  }


  .bannerTab {
    display: block;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(230, 13%, 9%, 0.05),
      0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    border-radius: 0.375em;
    transition: 0.3s;
    width: 100%;
    margin-inline: auto;
    height: 300px !important;
  }
  .bannerMobile {
    display: block;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(230, 13%, 9%, 0.05),
      0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    border-radius: 0.375em;
    transition: 0.3s;
    width: 100%;
    margin-inline: auto;
    height: 162px !important;
  }

  .bannerUltrawide {
    display: block;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(230, 13%, 9%, 0.05),
      0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
      0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
      0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    border-radius: 0.375em;
    transition: 0.3s;
    width: 65%;
    margin-inline: auto;
    height: 300px !important;
  }
  
  .banner__grid {
    display: flex;
    flex-direction: column;
  }
  
  .banner__grid > * {
    min-width: 0;
  }
  
  .banner__link {
    position: relative;
    text-decoration: none;
    color: #89C000;
    display: inline-block;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: left bottom;
  }
  
  .banner__link i {
    /* label */
    position: relative;
    z-index: 2;
    display: inline-block;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .banner__link::after {
    /* animated border */
    content: "";
    background-color: currentColor;
    height: 2px;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    -webkit-clip-path: inset(0% round 0.1875em);
    clip-path: inset(0% round 0.1875em);
    opacity: 0.15;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .banner__figure {
    height: 0;
    width: 100%;
    padding-bottom: 50%;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .banner__text {
    /* padding: 1.5rem; */
    padding-left: 65px;
    padding-top: 110px
  }
  
  /* For mobile */
  @media (min-width: 200px){
  .banner__textMobile {
    /* padding: 1.5rem; */
    padding-left: 24px;
    padding-top: 174px
  }
}

/* For Tab */
  @media (min-width: 500px){
  .banner__textMobile {
    /* padding: 1.5rem; */
    padding-left: 60px;
    padding-top: 120px
  }
}
  
  .banner__link-wrapper {
    margin-top: 7rem;
  }
  
  .banner:hover {
    box-shadow: 0 0 0 1px hsla(230, 13%, 9%, 0.05),
      0 0.9px 1.25px hsla(230, 13%, 9%, 0.025), 0 3px 5px hsla(230, 13%, 9%, 0.05),
      0 12px 20px hsla(230, 13%, 9%, 0.09);
  }
  
  .banner:hover .banner__link {
    transform: scale(1.3);
  }
  
  .banner:hover .banner__link i {
    transform: scale(0.7);
  }
  
  .banner:hover .banner__link::after {
    height: 100%;
  }
  
  .banner:hover .banner__figure {
    transform: scale(1.05);
  }
  
  @media (min-width: 64rem) {
    .banner__grid {
      flex-direction: row-reverse;
    }
  
    .banner__grid > * {
      width: 50%;
    }
  
    .banner__figure {
      height: 100%;
      padding-bottom: 0;
      -webkit-clip-path: polygon(100px 0%, 100% 0%, 100% 100%, 50px 100%);
      clip-path: polygon(100px 0%, 100% 0%, 100% 100%, 50px 100%);
    }
  
    .banner__text {
      /* padding: 3rem; */
      padding-left: 65px;
    padding-top: 110px
    }
  
    .banner__link-wrapper {
      margin-top: 7rem;
    }
  
    .banner--invert .banner__grid {
      flex-direction: row;
    }
  
    .banner--invert .banner__figure {
      -webkit-clip-path: polygon(
        0% 0%,
        calc(100% - 100px) 0%,
        calc(100% - 50px) 100%,
        0% 100%
      );
      clip-path: polygon(
        0% 0%,
        calc(100% - 100px) 0%,
        calc(100% - 50px) 100%,
        0% 100%
      );
    }
  
    .banner--invert .banner__text {
      text-align: right;
    }
  
    .banner--invert .banner__link {
      transform-origin: right bottom;
    }
  
    .banner:hover .banner__figure {
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
  
  
  /* === ERROR and Maintenance Section === */
@import url(https://fonts.googleapis.com/css?family=opensans:500);

/* body {
    background: #89c000;
    color: #1C2951;
    font-family: 'Open Sans', sans-serif;
    max-height: 700px;
    overflow: hidden;
} */

.c {
    text-align: center;
    display: block;
    position: relative;
    width: 80%;
    margin: 100px auto;
}

._404 {
    font-size: 220px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 250px;
    letter-spacing: 15px;
}

._1 {
    padding-top: 25px;
    text-align: center;
    display: block;
    position: relative;
    letter-spacing: 12px;
    font-size: 4em;
    line-height: 80%;
}

._2 {
    padding-bottom: 25px;
    text-align: center;
    display: block;
    position: relative;
    font-size: 20px;
}

.text {
    font-size: 70px;
    text-align: center;
    position: relative;
    display: inline-block;
    margin: 19px 0px 0px 0px;
    z-index: 3;
    width: 100%;
    line-height: 1.2em;
    display: inline-block;
}

/* === btn Start === */

.wrap {
	text-align: center;
}

/* a {
	-webkit-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
	-moz-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
	-ms-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
	-o-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
	transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
	display: block; */
	/* margin: 20px auto; */
	/* max-width: 240px; */
	/* text-decoration: none; */
	/* border-radius: 8px; */
	/* padding: 10px 15px; */
/* } */

a.button {
	color: white;
    background-color: #89C000;
    border-radius: 50px;
	box-shadow: rgb(255, 255, 255) 0 0px 0px 2.5px inset;
}

a.button:hover {
	color: rgb(255, 255, 255);
	box-shadow: rgb(28, 41, 81) 0 0px 0px 80px inset;
}


/* === btn End === */

.cloud {
    width: 350px;
    height: 120px;

    background: #FFF;
    background: linear-gradient(to bottom, #FFF 100%);
    background: -webkit-linear-gradient(to bottom, #FFF 100%);
    background: -moz-linear-gradient(to bottom, #FFF 100%);
    background: -ms-linear-gradient(to bottom, #FFF 100%);
    background: -o-linear-gradient(to bottom, #FFF 100%);

    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;

    position: absolute;
    margin: 120px auto 20px;
    z-index: -1;
    transition: ease 1s;
}

.cloud:after,
.cloud:before {
    content: '';
    position: absolute;
    background: #FFF;
    z-index: -1
}

.cloud:after {
    width: 100px;
    height: 100px;
    top: -50px;
    left: 50px;

    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
}

.cloud:before {
    width: 180px;
    height: 180px;
    top: -90px;
    right: 50px;

    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
}

.x1 {
    top: -50px;
    left: 100px;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0.9;
    -webkit-animation: moveclouds 15s linear infinite;
    -moz-animation: moveclouds 15s linear infinite;
    -o-animation: moveclouds 15s linear infinite;
}

.x1_5 {
    top: -80px;
    left: 250px;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-animation: moveclouds 17s linear infinite;
    -moz-animation: moveclouds 17s linear infinite;
    -o-animation: moveclouds 17s linear infinite;
}

.x2 {
    left: 250px;
    top: 30px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.6;
    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
}

.x3 {
    left: 250px;
    bottom: -70px;

    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.8;

    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
}

.x4 {
    left: 470px;
    botttom: 20px;

    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.75;

    -webkit-animation: moveclouds 18s linear infinite;
    -moz-animation: moveclouds 18s linear infinite;
    -o-animation: moveclouds 18s linear infinite;
}

.x5 {
    left: 200px;
    top: 300px;

    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.8;

    -webkit-animation: moveclouds 20s linear infinite;
    -moz-animation: moveclouds 20s linear infinite;
    -o-animation: moveclouds 20s linear infinite;
}

@-webkit-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@-moz-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@-o-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}




/* === maintenance Start === */
* {
    box-sizing: border-box;
}
.maintenance {
    background-image: url('http://127.0.0.1:5501/assets/img/underWorkBg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}

.maintenance {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.maintenance {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.maintenance_contain {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;  
    padding: 15px;  
}
.maintenance_contain img {
    width: auto;
    max-width: 100%;
}
.pp-infobox-title-prefix {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-top: 30px;
    text-align: center;
}

/* .pp-infobox-title-prefix {
    font-family: sans-serif;
} */

.pp-infobox-title {
    color: #000000;
    /* font-family: sans-serif; */
    font-weight: 700;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: block;
    word-break: break-word;  
}

.pp-infobox-description {
    color: #000000;
    /* font-family: "Poppins", sans-serif; */
    font-weight: 400;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.pp-infobox-description p {
    margin: 0;
}

.title-text.pp-primary-title {
    color: #000000;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    /* font-family: sans-serif; */
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 50px;
    margin-bottom: 0px;
}

.pp-social-icon {
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    line-height: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}

.pp-social-icon a {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.pp-social-icon a i {
    border-radius: 100px;
    font-size: 20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
}

.pp-social-icon:nth-child(1) a i {
    color: #4b76bd;
}
.pp-social-icon:nth-child(1) a i {
    border: 2px solid #4b76bd;
}
.pp-social-icon:nth-child(2) a i {
    color: #00c6ff;
}
.pp-social-icon:nth-child(2) a i {
    border: 2px solid #00c6ff;
}
.pp-social-icon:nth-child(3) a i {
    color: #fb5245;
}
.pp-social-icon:nth-child(3) a i {
    border: 2px solid #fb5245;
}
.pp-social-icon:nth-child(4) a i {
    color: #158acb;
}
.pp-social-icon:nth-child(4) a i {
    border: 2px solid #158acb;
}

.pp-social-icons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

/* === Maintenance End === */
  
.break-after-30 {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  max-width: 49ch;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}